import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query GetHomePortrait {
      file(relativePath: { eq: "drysso2020_450x630.jpg" }) {
        childImageSharp {
          fixed(width: 225, height: 315) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column solidBg is-half-desktop is-one-third-fullhd">
              <Img
                fixed={data.file.childImageSharp.fixed}
                alt="Deborah Rysso"
              />
            </div>
            <div className="column solidBg has-text-right is-half-desktop is-one-third-fullhd">
              <h1 class="title is-size-1 is-size-2-mobile has-text-white">
                Specializing in Elder Law
              </h1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full is-8-widescreen">
              <h2 className="title is-size-2 is-size-3-mobile">
                Traverse City's premier elder law firm, specializing in
                Medicaid, estate planning, trusts, wills, Powers of Attorney,
                probate, and VA Aid &amp; Attendance benefits.
              </h2>
              <h4 className="subtitle is-size-3 is-size-4-mobile">
                Rysso Law, PLLC has caring and knowledgeable elder law attorneys
                to help your family navigate through the legal issues affecting
                your loved ones. <Link to="/contact-us/">EMAIL US TODAY</Link>{" "}
                or call/text <a href="tel:231-649-2903">231-649-2903</a>.
              </h4>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
